<template>
  <div class="user-tab-security">
    <v-card class="mb-7">
      <v-card-title class="d-flex align-center justify-space-between">
        <span v-t="'editor'" />
        <v-dialog v-model="isSendTestEmailDialogOpen" max-width="650px" scrollable>
          <template #activator="{ on, attrs }">
            <v-btn
              class="mx-3"
              color="primary"
              outlined
              small
              v-bind="attrs"
              v-on="on"
              @click="isSendTestEmailDialogOpen = true"
              >{{ $t('testEmail') }}</v-btn
            >
          </template>
          <v-card class="pa-sm-10 pa-3">
            <v-card-title v-t="'send'" class="justify-center text-h5" />
            <v-card-text style="max-height: 500px">
              <v-form
                ref="testEmailVariablesForm"
                v-model="testEmailVariablesValid"
                class="multi-col-validation"
                @submit.prevent="sendTestEmailHandler"
              >
                <v-subheader>Email</v-subheader>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="emailToTest"
                      outlined
                      dense
                      maxlength="150"
                      hide-details="auto"
                      :label="$t('emailTo')"
                      :placeholder="$t('emailTo')"
                      :rules="[validators.required, validators.multipleEmailValidator]"
                      :hint="$t('separateByComma')"
                    />
                  </v-col>
                </v-row>
                <v-subheader v-t="'variables'" />
                <v-row v-for="(variableData, index) in testEmailVariables" :key="index">
                  <v-col v-if="isEmailVariableUsed(variableData.variable)" cols="12">
                    <v-text-field
                      v-model="variableData.testValue"
                      :label="`${variableData.label}*`"
                      :maxlength="variableData.maxLength"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="[validators.required]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-3">
                    <v-btn v-t="'discard'" outlined color="secondary" class="me-3" @click="closeSendTestEmailDialog" />
                    <v-btn
                      color="primary"
                      type="submit"
                      :loading="sendingTestEmail"
                      :disabled="!testEmailVariablesValid"
                    >
                      <template v-if="!showCheckIcon">
                        <span class="me-2">Send</span>
                        <v-icon small>{{ icons.mdiSendOutline }}</v-icon>
                      </template>
                      <template v-else>
                        <v-icon>{{ icons.mdiCheck }}</v-icon>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="defaultEmailTemplateData.subject"
              outlined
              dense
              maxlength="150"
              hide-details="auto"
              :label="$t('subject')"
              :placeholder="$t('subject')"
              :rules="[validators.required]"
              readonly
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <summer-note :disabled="true" hide-link-and-image-button @ready="readySummerNote" />
            <div class="float-right text-xs">{{ defaultEmailTemplateData.bodyHtml.length }} / 20000</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { mdiCheck, mdiSendOutline } from '@mdi/js'

import { required, multipleEmailValidator } from '@core/utils/validation'

import SummerNote from '@/plugins/summer-note/SummerNote.vue'

import {
  TEST_EMAIL_VARIABLES_WHEN_STATUS_CHANGED_DATA,
  TEST_EMAIL_VARIABLES_RENEW_TALENT_POOL_CONSENT_DATA,
  TEST_EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_DATA,
} from '@/plugins/summer-note/variables'

const EMAIL_RENEW_TALENT_POOL_CONSENT_ACTION = 'renew_tp_consent'
const EMAIL_INVITE_TO_JOB_FROM_TALENT_POOL_ACTION = 'invite_to_job_tp'

export default {
  components: {
    SummerNote,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const { defaultEmailTemplateData } = useState('jobs', ['defaultEmailTemplateData'])
    const { sendTestEmail } = useActions('jobs', ['sendTestEmail'])

    const editor = ref(null)
    const rerenderSummernoteTrigger = ref(false)

    const testEmailVariables = computed(() => {
      if (defaultEmailTemplateData?.value?.action === EMAIL_RENEW_TALENT_POOL_CONSENT_ACTION)
        return TEST_EMAIL_VARIABLES_RENEW_TALENT_POOL_CONSENT_DATA(vm)
      if (defaultEmailTemplateData?.value?.action === EMAIL_INVITE_TO_JOB_FROM_TALENT_POOL_ACTION)
        return TEST_EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_DATA(vm)

      return TEST_EMAIL_VARIABLES_WHEN_STATUS_CHANGED_DATA(vm)
    })

    const readySummerNote = (initializedEditor) => {
      editor.value = initializedEditor
      editor.value.summernote('code', defaultEmailTemplateData.value.bodyHtml)
    }

    const isSendTestEmailDialogOpen = ref(false)
    const emailToTest = ref('')
    const sendingTestEmail = ref(false)
    const showCheckIcon = ref(false)
    const testEmailVariablesValid = ref(false)
    const testEmailVariablesForm = ref(null)

    const isEmailVariableUsed = (v) => {
      const variable = `{{ ${v} }}`

      return (
        defaultEmailTemplateData.value.bodyHtml.includes(variable) ||
        defaultEmailTemplateData.value.subject.includes(variable)
      )
    }

    const closeSendTestEmailDialog = () => {
      testEmailVariablesForm.value.reset()
      isSendTestEmailDialogOpen.value = false
    }

    const sendTestEmailHandler = async () => {
      sendingTestEmail.value = true
      const emailSendSuccessfully = await sendTestEmail({
        emailsTo: emailToTest.value,
        subject: defaultEmailTemplateData.value.subject,
        lang: defaultEmailTemplateData.value.lang,
        bodyHtml: defaultEmailTemplateData.value.bodyHtml,
        variables: testEmailVariables.value,
        action: defaultEmailTemplateData.value.action,
      })
      sendingTestEmail.value = false
      if (emailSendSuccessfully) {
        showCheckIcon.value = true
        setTimeout(() => {
          showCheckIcon.value = false
          closeSendTestEmailDialog()
        }, 2500)
      }
    }

    return {
      readySummerNote,
      isEmailVariableUsed,
      sendTestEmailHandler,
      closeSendTestEmailDialog,

      isSendTestEmailDialogOpen,
      emailToTest,
      sendingTestEmail,
      testEmailVariablesValid,
      testEmailVariablesForm,
      showCheckIcon,

      defaultEmailTemplateData,
      testEmailVariables,
      rerenderSummernoteTrigger,

      validators: {
        required,
        multipleEmailValidator,
      },

      icons: {
        mdiCheck,
        mdiSendOutline,
      },
    }
  },
}
</script>
