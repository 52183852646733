<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            emailTemplateName: defaultEmailTemplateData.title,
          }"
        />
        <default-email-bio-panel />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <default-email-tab-editor v-if="defaultEmailTemplateData.subject" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onUnmounted, onMounted } from '@vue/composition-api'
import { useMutations, useState } from 'vuex-composition-helpers'
import DefaultEmailBioPanel from './default-email-bio-panel/DefaultEmailBioPanel.vue'
import DefaultEmailTabEditor from './default-email-tab-editor/DefaultEmailTabEditor.vue'
import useEmailList from '../email-list/useEmailList'
import router from '@/router'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'

export default {
  components: {
    BreadCrumbs,
    DefaultEmailBioPanel,
    DefaultEmailTabEditor,
  },
  setup() {
    const { defaultEmailTemplateData } = useState('jobs', ['defaultEmailTemplateData'])
    const { SET_DEFAULT_EMAIL_TEMPLATE_DATA } = useMutations('jobs', ['SET_DEFAULT_EMAIL_TEMPLATE_DATA'])

    const { defaultEmailTemplates } = useEmailList()

    onMounted(() => {
      SET_DEFAULT_EMAIL_TEMPLATE_DATA(
        defaultEmailTemplates.find((et) => et.action === router.currentRoute.params.action),
      )
    })

    onUnmounted(() => {
      SET_DEFAULT_EMAIL_TEMPLATE_DATA({})
    })

    return {
      defaultEmailTemplateData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
