<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <span class="mb-2">{{ defaultEmailTemplateData.title }}</span>
          <v-chip
            label
            small
            :color="resolveEmailActionIcon(defaultEmailTemplateData.action).color"
            :class="`v-chip-light-bg ${
              resolveEmailActionIcon(defaultEmailTemplateData.action).color
            }--text font-weight-semibold text-capitalize ma-1 mb-2`"
          >
            {{ $t(resolveEmailActionIcon(defaultEmailTemplateData.action).text) }}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <h2 v-t="'details'" class="text-xl font-weight-semibold mb-2" />

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('language') }}:</span>
              <span class="text--secondary">{{ $t(resolveEmailLanguageText(defaultEmailTemplateData.lang)) }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { formatDateToMonthShort } from '@core/utils/filter'
import { useState } from 'vuex-composition-helpers'
import useEmailList from '../../email-list/useEmailList'

export default {
  setup() {
    const { defaultEmailTemplateData } = useState('jobs', ['defaultEmailTemplateData'])
    const { resolveEmailActionIcon, resolveEmailLanguageText } = useEmailList()

    return {
      resolveEmailActionIcon,
      resolveEmailLanguageText,
      formatDateToMonthShort,

      defaultEmailTemplateData,
    }
  },
}
</script>
